<template>
  <el-dialog
      title="婚纱详情"
      width="75%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row>
      <el-col :span="10">
        <el-collapse style="width:97%;margin: 0 auto" v-model="collapseActive">
          <el-collapse-item v-for="(item,index) in styleImageList" :key="item.id" :title="item.typeName" :name="index">
            <el-row :gutter="10">
              <el-col :span="12" style="margin: 1% 0;height: 22vw" v-for="(itemChild,indexChild) in item.image" :key="indexChild">
                <div style="position: relative;">
                  <img
                      style="width:100%;height: 100%;" @click="openImage(itemChild.styleImage)" alt=""
                      :src="`https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/${itemChild.styleImage}?imageMogr2/rquality/60`"/>
                </div>
              </el-col>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </el-col>
      <el-col :span="13" :offset="1">
        <el-row>
          <el-tag v-for="item in clothesLabel" :key="item.id"
                  type="" effect="dark"
                  style="margin: 0 1%">
            {{ item.labelName }}
          </el-tag>
        </el-row><br>
        <el-row>
          <el-input readonly :value="clothesName">
            <template slot="prepend">婚纱名称</template>
          </el-input>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input readonly :value="details.styleAlias">
              <template slot="prepend">款式名称</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-input readonly :value="details.brand">
              <template slot="prepend">款式品牌</template>
            </el-input>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input readonly :value="details.styleType">
              <template slot="prepend">款式类型</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-input readonly :value="details.styleName">
              <template slot="prepend">款式编号</template>
            </el-input>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input readonly :value="details.clothesSize">
              <template slot="prepend">婚纱尺寸</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-input readonly :value="details.clothesNo">
              <template slot="prepend">婚纱编号</template>
            </el-input>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input readonly :value="details.shopName">
              <template slot="prepend">所在店铺</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-input readonly :value="details.positionName">
              <template slot="prepend">所在区域</template>
            </el-input>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input readonly :value="count.yarnCount">
              <template slot="prepend">试纱次数</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-input readonly :value="count.outCount">
              <template slot="prepend">出件次数</template>
            </el-input>
          </el-col>
        </el-row><br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input readonly :value="details.purchaseDate">
              <template slot="prepend">采购日期</template>
            </el-input>
          </el-col>
          <!--        <el-col :span="12">-->
          <!--          <el-input readonly :value="details.factoryName">-->
          <!--            <template slot="prepend">采购来源</template>-->
          <!--          </el-input>-->
          <!--        </el-col>-->
        </el-row>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "share-clothes-detail",
  data() {
    return {
      details:{},
      clothesName:"",
      clothesLabel: [],
      dialogState: this.state,
      collapseActive:[],
      styleImageList: [],
      count: {
        yarnCount: 0,
        outCount: 0,
      },
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    clothesInfo: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryClothesDetails();
    this.queryStyleImage();
    this.queryStyleLabel();
    this.queryCount()
  },
  methods: {
    //查询婚纱详情
    queryClothesDetails(){
      this.$axios({
        method:"GET",
        url: "/clothes/queryClothesVo",
        params: {
          id: this.clothesInfo.clothesId
        }
      }).then(response=>{
        this.details=response.data.data
        this.clothesName=this.details.styleType+ '-' + this.details.styleName+'-' + this.details.clothesSize + '-' + this.details.clothesNo
      })
    },
    //查询款式标签
    queryStyleLabel(){
      this.$axios({
        method:"GET",
        url: "/styleLabel/queryStyleLabelVoByClothesId",
        params: {
          clothesId: this.clothesInfo.clothesId
        }
      }).then(response=>{
        this.clothesLabel=response.data.data
      })
    },
    //查询款式图片
    queryStyleImage() {
      this.$axios({
        method: "GET",
        url: "/styleImage/queryStyleImageList",
        params: {
          styleId: this.clothesInfo.styleId
        }
      }).then(response => {
        this.styleImageList = []
        this.styleImageList.push(...this.arrGroupBy(response.data.data));
        for (let index in this.styleImageList){
          this.collapseActive.push(Number(index))
        }
      })
    },
    //放大图片
    openImage(link) {
      this.$viewerApi({
        images: ['https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/' + link],
      })
    },
    close(){
      this.$emit("close");
    },
    queryCount() {
      this.$axios({
        method: "get",
        url: "/clothes/queryYarnCountAndOutCountByClothesId",
        params: {
          clothesId: this.clothesInfo.clothesId,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.count = response.data.data
      })
    },
    //图片根据类型分组
    arrGroupBy(array){
      if (array===undefined||array===null||array===[]||array.length===0) return [];
      //获取去重后的图片类型
      let typeName=[];
      array.map(k=>{return k.imageTypeName}).forEach(k=>{
        if (!typeName.includes(k)){
          typeName.push(k)
        }
      })

      let newArr=[];
      typeName.forEach(k=>{
        let arrItem={typeName:k,image:[]}
        array.forEach(v=>{
          if(k===v.imageTypeName){
            arrItem.image.push(v)
          }
        })
        newArr.push(arrItem)
      })
      return newArr;
    },
  }
}
</script>

<style scoped>
/*/deep/ .el-carousel__item.is-active.is-animating{*/
/*  text-align: center;*/
/*}*/
/*/deep/ .el-dialog.el-dialog--center{*/
/*  height: 90%;*/
/*}*/
.details{
  width: 70%;
}
</style>