<template>
  <el-drawer
      title="购物车"
      :visible.sync="dialogState"
      direction="rtl"
      size="30%"
      @close="close">

    <el-table
        :data="shoppingCartList"
        style="width: 100%">
      <el-table-column
          type="index"
          align="center"
          width="50">
      </el-table-column>
      <el-table-column
          label="图片"
          width="180"
          prop="tenantName" align="center">
        <template slot-scope="scope">
          <el-image
              style="width: 100px; height: 100px"
              @click="openStyleImages(scope.row.styleId)"
              :src="`https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/${scope.row.styleImage}?imageMogr2/rquality/60`"
              fit="contain"></el-image>
        </template>
      </el-table-column>
      <el-table-column
          label="名称"
          width="180"
          align="center">
        <template slot-scope="scope">
          <p>{{`${scope.row.styleType}-${scope.row.styleName}-${scope.row.clothesSize}-${scope.row.clothesNo}`}}</p>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
              size="mini"
              @click="delShoppingCart(scope.row)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <br><br><br><br>
    <el-row>
      <el-form :model="form" :rules="rules" ref="form" >
        <el-row>
          <el-col :span="11" :offset="1">
            <el-form-item label="使用时间" prop="usageDate">
              <el-select v-model="form.usageDate" placeholder="使用时间" ref="usageDate">
                <el-option label="一个月" value="1"/>
                <el-option label="三个月" value="3"/>
                <el-option label="六个月" value="6"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11" :offset="1">
            <el-form-item label="目标店铺" prop="shopId">
              <el-select v-model="form.shopId" placeholder="目标店铺" @change="queryTypesByShopId" ref="shop">
                <el-option
                    v-for="item in shopIdArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21" :offset="1">
            <el-form-item label="备注">
              <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入备注"
                  v-model="form.remark">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="text-align: center">
          <el-form-item>
            <el-button type="primary" @click="addTransportApplyMulti()">提交调货申请</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-row>
  </el-drawer>
</template>

<script>
export default {
  name: "share-clothes-shopping-cart",
  data() {
    return {
      form:{
        empId:JSON.parse(localStorage.getItem("emp")).id,
        clothesIds:[],
        usageDate:"",
        shopId:"",
        positionId:"",
        remark:"",
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      shopIdArray: [],
      rules: {
        shopId: [{required: true, message: "请选择店铺", trigger: "change"}],
        usageDate: [{required: true, message: "请选择使用时间", trigger: "change"}],
      },
      shoppingCartList:[],
      dialogState: this.state,

    }
  },
  created() {
    this.queryShoppingCart();
    this.pageInit();
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods:{
    //查询购物车
    queryShoppingCart(){
      this.$axios({
        method: "GET",
        url: "/clothes/queryShoppingCart",
        params: {
          tenantCrop:this.form.tenantCrop,
        }
      }).then(response => {
        this.shoppingCartList=response.data.data;
        this.form.clothesIds=this.shoppingCartList.map(k=>k.clothesId);
      })
    },
    //删除购物车的数据
    delShoppingCart(item){
      this.$axios({
        method: "POST",
        url: "/clothes/delShoppingCart",
        params: {
          id:item.clothesId,
          tenantCrop:this.form.tenantCrop,
        }
      }).then(response => {
        let flag=response.data.code===200;
        this.$message({
          type: flag?'success':'error',
          message: flag?'删除成功':'删除失败',
        });
        this.queryShoppingCart();
      })
    },
    addTransportApplyMulti(){
      this.$refs['form'].validate(valid => {
        if (!valid) return false;
        if (this.form.positionId===""||this.form.positionId===null||this.form.positionId===undefined){
          this.$message.error("请添加收件待定区");
          return false;
        }
        this.$alert("是否发起调货申请?",
            "申请调货",
            {
              dangerouslyUseHTMLString: true,
              type: "success",
              showCancelButton: true
            }).then(() => {
          this.$axios({
            method:"PUT",
            url:"/libClothesTransport/addTransportApplyMulti",
            params: {
              clothesIds:this.form.clothesIds.toString(),
              empId:this.form.empId,
              shopId:this.form.shopId,
              positionId:this.form.positionId,
              usageDate:this.form.usageDate,
              remark:this.form.remark,
              tenantCrop:this.form.tenantCrop,
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '操作成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            if (flag) {
              this.close();
            }
          })
        })
      })
    },
    //选择店铺后自动选择收件待定区
    pageInit() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopIdArray = JSON.parse(response.data.data);
      })
    },
    //查找默认位置
    queryTypesByShopId(shopId) {
      this.$axios({
        method: "GET",
        url: "/position/queryTypesByShopId",
        params: {
          shopId:shopId,
        }
      }).then(response => {
        let choosePosition=response.data.data.filter(k=>{return k.type==='收件待定区'});
        if (choosePosition.length===0||choosePosition.length>1){
          this.$message.error(choosePosition.length===0?'请在该店铺添加新位置: "收件待定区" ':'一个店铺只能有一个 "收件待定区" ');
          return;
        }
        this.form.positionId=choosePosition[0].id;
      })
    },
    openStyleImages(styleId){
      this.$axios({
        method: "GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: styleId
        }
      }).then(response => {
        this.$viewerApi({
          images: response.data.data,
        })
      })
    },
    close(){
      this.$emit("close");
    },
  },
}
</script>

<style scoped>

</style>