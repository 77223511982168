<template>
  <el-dialog
      title="申请调货"
      width="75%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-form label-width="80px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="5">
          <el-form-item label="婚纱名称">
            <el-input readonly :value="clothesName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-form-item label="操作日期" prop="createDate">
            <el-date-picker type="date" placeholder="操作日期"
                            v-model="form.createDate"
                            style="width: 100%;"
                            value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-form-item label="当前店铺">
            <el-input readonly v-model="clothesInfo.shopName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-form-item label="当前位置">
            <el-input readonly v-model="clothesInfo.positionName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <el-form-item label="申请人" prop="empId">
            <el-select v-model="form.empId" placeholder="申请人">
              <el-option
                  v-for="item in empIdArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
<!--        <el-col :span="5" :offset="1">-->
<!--          <el-form-item label="申请理由" prop="reasonId">-->
<!--            <el-select v-model="form.reasonId" placeholder="申请理由" ref="reasonId">-->
<!--              <el-option label="试纱" value="1"/>-->
<!--              <el-option label="出件" value="2"/>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="5" :offset="1">
          <el-form-item label="使用时间" prop="usageDate">
            <el-select v-model="form.usageDate" placeholder="使用时间" ref="usageDate">
              <el-option label="一个月" value="1"/>
              <el-option label="三个月" value="3"/>
              <el-option label="六个月" value="6"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5" :offset="1">
          <el-form-item label="目标店铺" prop="shopId">
            <el-select v-model="form.shopId" placeholder="目标店铺" @change="queryClothesPosition" ref="shop">
              <el-option
                  v-for="item in shopIdArray"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
<!--        <el-col :span="5" :offset="1">-->
<!--          <el-form-item label="目标位置" prop="positionId" >-->
<!--            <el-select v-model="form.positionId" placeholder="目标位置" ref="position">-->
<!--              <el-option-->
<!--                  v-for="item in clothesPositionArray"-->
<!--                  :key="item.value"-->
<!--                  :label="item.name"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="操作描述">
            <el-input
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 4}"
                v-model="form.remark">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "share-clothes-transport",
  data() {
    return {
      form:{
        createDate:new Date(),
        clothesId:this.clothesInfo.clothesId,
        empId: JSON.parse(localStorage.getItem("emp")).id,
        reasonId: 1,
        usageDate: "",
        localShopId:this.clothesInfo.shopId,
        localPositionId:this.clothesInfo.positionId,
        shopId: "",
        positionId: "",
        remark: "",
        state:0,
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      rules: {
        createDate: [{required: true, message: "请选择操作日期", trigger: "change"}],
        shopId: [{required: true, message: "请选择经办人", trigger: "change"}],
        empId: [{required: true, message: "请选择目标店铺", trigger: "change"}],
        reasonId: [{required: true, message: "请选择申请理由", trigger: "change"}],
        usageDate: [{required: true, message: "请选择使用时间", trigger: "change"}],
        positionId: [{required: true, message: "请选择目标位置", trigger: "change"}],
      },
      clothesName:`${this.clothesInfo.styleType}-${this.clothesInfo.styleName}-${this.clothesInfo.clothesSize}-${this.clothesInfo.clothesNo}`,
      empIdArray: [],
      shopIdArray: [],
      clothesPositionArray: [],
      dialogState: this.state,
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    clothesInfo: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.pageInit();
  },
  methods:{
    submit(){
      if (this.form.positionId===""||this.form.positionId===null||this.form.positionId===undefined){
        this.$message.error("请添加收件待定区");
        return false;
      }
      this.$refs['form'].validate(valid => {
        if (!valid) return false;
        this.$alert("是否发起 "+this.clothesName+" 调货的申请?",
            "婚纱操作",
            {
              dangerouslyUseHTMLString: true,
              type: "success",
              showCancelButton: true
            }).then(() => {
          this.$axios({
            method:"PUT",
            url:"/libClothesTransport/addTransportApply",
            params: {
              ...this.form
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '操作成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            if (flag) {
              this.close();
            }
          })
        })
      })

      console.log(this.form)
    },
    //选择店铺后自动选择收件待定区
    queryClothesPosition(shopId) {
      this.$selectUtils.queryPositionIdsByShop(shopId).then(response => {
        this.clothesPositionArray = JSON.parse(response.data.data);
        let choosePosition=this.clothesPositionArray.filter(k=>{return k.name==='收件待定区'});
        if (choosePosition.length===0||choosePosition.length>1){
          this.$message.error(choosePosition.length===0?'请在该店铺添加新位置: "收件待定区" ':'一个店铺只能有一个 "收件待定区" ');
          return;
        }
        this.form.positionId=choosePosition[0].value;
      })
    },
    pageInit() {
      this.$selectUtils.queryEmpIds().then(response=>{
        this.empIdArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopIdArray = JSON.parse(response.data.data);
      })
    },
    close(){
      this.$emit("close");
    },
  },
}
</script>

<style scoped>

</style>